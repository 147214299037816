.upcoming-courses {
  padding: var(--padding);
  width: 100%;
  max-width: 1700px;
  margin: 0 auto;
  position: relative;
}

.upcoming-courses__kicker {
  font-size: var(--meta);
  font-family: var(--title);
  line-height: var(--meta-lh);
  margin-bottom: 15px;
  display: block;
}

.upcoming-courses__button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
