.filter-options {
  padding: var(--padding-top) var(--padding-sides) 0;
  position: relative;
  max-width: 1700px;
  margin: 0 auto;
}

@media (min-width: 680px) {
  .filter-options__toggle::after {
    font-size: 14px;
  }
}

.filter-options__toggle {
  font-family: var(--title);
  font-size: var(--meta);
  position: relative;
  cursor: pointer;
  padding-right: 25px;
  display: inline-block;
}

.filter-options__toggle::after {
  content: "";
  width: 8px;
  height: 8px;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
  transform: rotate(45deg);
  right: 5px;
  bottom: 7px;
  position: absolute;
  transition: transform 100ms ease-in-out;
}

.filter-options__filter--visible .filter-options__toggle::after {
  transform: rotate(-135deg);
  bottom: 3px;
}

@media (min-width: 1000px) {
  .filter-options__toggle::after {
    transform: rotate(-135deg);
  }

  .filter-options__filter--visible .filter-options__toggle::after {
    transform: rotate(45deg);
  }
}

@media (min-width: 680px) {
  .filter-options__toggle::after {
    bottom: 7px;
  }

  .filter-options__filter--visible .filter-options__toggle::after {
    bottom: 2px;
  }
}

@media (min-width: 1000px) {
  .filter-options__toggle::after {
    bottom: 2px;
  }

  .filter-options__filter--visible .filter-options__toggle::after {
    bottom: 8px;
    width: 9px;
    height: 9px;
  }
}

.filter-options__clear {
  font-family: var(--title);
  font-size: 12px;
  cursor: pointer;
  margin: 0 0 0 20px;
  display: inline-block;
  border-bottom: 2px solid;
  position: relative;
  color: #d4d4d4;
  bottom: 3px;
}

.filter-options__clear--black {
  color: black;
}

.filter-options__clear--black:hover {
  color: salmon;
}

.filter-options__filter-wrapper {
  display: none;
}

.filter-options__filter--visible .filter-options__filter-wrapper {
  display: block;
  margin-top: 15px;
}

@media (min-width: 1000px) {
  .filter-options__filter-wrapper {
    display: block;
    margin-top: 15px;
  }

  .filter-options__filter--visible .filter-options__filter-wrapper {
    display: none;
  }
}

.filter-options__inner {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
}

@media (min-width: 1000px) {
  .filter-options__inner {
    max-width: 90%;
  }
}

.filter-options__label {
  font-family: var(--title);
  font-size: var(--meta-s);
  padding-bottom: 10px;
  display: block;
  margin: 0px 10px 10px 0px;
  padding: 7px 0 7px;
}
