.page {
  padding: var(--padding);
  width: 100%;
  max-width: 1700px;
  margin: 0 auto;
  position: relative;
  display: block;
}

@media (min-width: 800px) {
  .page {
    display: grid;
    grid-template-columns: var(--columns);
    grid-column-gap: var(--gap);
  }
}

.page__inner {
  width: 100%;
}

@media (min-width: 680px) {
  .page__inner {
    grid-column: 1 / 5;
  }
}

@media (min-width: 1000px) {
  .page__inner {
    grid-column: 1 / 8;
  }
}

.page__aside {
  width: 100vw;
  left: -20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

@media (min-width: 680px) {
  .page__aside {
    padding-left: 30px;
    grid-column: 5 / -1;
    left: 0;
    width: unset;
  }
}

@media (min-width: 1000px) {
  .page__aside {
    grid-column: 8 / -1;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

.page__aside .add-info-item {
  width: 100%;
}
