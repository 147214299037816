.kurse-liste__item {
  padding: 6px 0;
  border-bottom: 2px solid black;
  display: grid;
  grid-template-columns: var(--columns);
  grid-column-gap: var(--gap);
  padding: 15px 0px 9px;
  position: relative;
}

.kurse-liste__item:first-child {
  border-top: 2px solid black;
}

.kurse-liste__item:last-child {
  margin-bottom: 50px;
}

.kurse-liste__date-wrapper {
  grid-column: 1 / -2;
  padding-top: 4px;
}

@media (min-width: 680px) {
  .kurse-liste__date-wrapper {
    grid-column: 1 / 3;
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 1000px) {
  .kurse-liste__date-wrapper {
    grid-column: 1 / 3;
    padding-top: 10px;
  }
}
.kurse-liste__title {
  line-height: var(--liste-lh);
  font-family: var(--liste-font);
  font-size: var(--liste-titel);
  grid-column: 1 / -2;
  padding-top: 5px;
  hyphens: auto;
  -webkit-hyphens: auto;
}

@media (min-width: 680px) {
  .kurse-liste__title {
    grid-column: 3 / -2;
  }
}

@media (min-width: 1000px) {
  .kurse-liste__title {
    grid-column: 3 / -2;
    padding-top: 0;
    hyphens: unset;
    -webkit-hyphens: unset;
  }
}
.kurse-liste__filter {
  line-height: var(--meta-lh);
  font-size: var(--meta-s);
  grid-column: 1 / -2;
  margin-top: 8px;
}

@media (min-width: 680px) {
  .kurse-liste__filter {
    grid-column: 3 / -2;
  }
}

@media (min-width: 1000px) {
  .kurse-liste__filter {
    grid-column: 3 / -2;
  }
}
.kurse-liste__title a:hover {
  color: var(--color-hover);
}

.kurse-liste__filter a {
  border-radius: 5px;
  box-shadow: 0px 0px 0px 1px var(--link-grey) inset;
  cursor: pointer;
  display: inline-block;
  font-family: var(--title);
  font-size: var(--meta-s);
  line-height: 2;
  margin: 0 10px 10px 0;
  padding: 4px 8px;
  transition-property: color, box-shadow;
  transition-duration: 0.3s;
}

.kurse-liste__filter a:hover {
  box-shadow: 0px 0px 0px 2px var(--black) inset;
  color: #000;
  transition-property: color, box-shadow;
  transition-duration: 0.3s;
}

.kurse-liste__filter--kategorien a {
  background: var(--link-grey);
  height: 34px;
}

.kurse-liste__filter--tags a {
  height: 34px;
}

.kurse-liste__filter--status {
  height: 34px;
  font-family: var(--title);
  color: tomato;
}

.kurse-liste__arrow {
  grid-column: -2/-1;
  grid-row: 1 / 4;
  height: 100%;
  position: relative;
}

@media (min-width: 650px) {
  .kurse-liste__arrow {
    grid-row: 1 / 3;
  }
}

.kurse-liste__arrow::after {
  content: "";
  width: 15px;
  height: 15px;
  border-top: 3px solid black;
  border-right: 3px solid black;
  transform: rotate(45deg) translateY(63%);
  right: 5px;
  bottom: 50%;
  position: absolute;
}

@media (min-width: 650px) {
  .kurse-liste__arrow::after {
    width: 25px;
    height: 25px;
  }
}

@media (min-width: 1000px) {
  .kurse-liste__arrow::after {
    width: 30px;
    height: 30px;
    border-top: 4px solid;
    border-right: 4px solid;
  }
}

.kurse-liste__arrow:hover {
  cursor: pointer;
}

.kurse-liste__arrow:hover::after {
  cursor: pointer;
  border-color: var(--color-hover);
}
