.dozent {
  padding: var(--padding);
  width: 100%;
  max-width: 1700px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}

@media (min-width: 680px) {
  .dozent {
    display: grid;
    grid-template-columns: var(--columns);
    grid-column-gap: var(--gap);
  }
}

.dozent__kurse {
  padding: var(--padding);
  width: 100%;
  max-width: 1700px;
  margin: 0 auto;
  position: relative;
}

.dozent__button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dozent__aside {
  width: 100%;
  position: relative;
}

@media (min-width: 680px) {
  .dozent__aside {
    padding-left: 30px;
    grid-column: 5 / -1;
  }
}

@media (min-width: 1000px) {
  .dozent__aside {
    grid-column: 8 / -1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

.dozent__tags {
  padding-top: 40px;
}

.dozent__tag {
  line-height: var(--meta-lh);
  font-size: var(--meta-s);
  grid-column: 1 / -2;
  margin-top: 8px;
}

@media (min-width: 680px) {
  .dozent__tag {
    grid-column: 3 / -2;
  }
}

@media (min-width: 1000px) {
  .dozent__tag {
    grid-column: 3 / -2;
  }
}

.dozent__tag a {
  border-radius: 5px;
  box-shadow: 0px 0px 0px 1px var(--link-grey) inset;
  cursor: pointer;
  display: inline-block;
  font-family: var(--title);
  font-size: var(--meta-s);
  line-height: 2;
  margin: 0 10px 10px 0;
  padding: 4px 8px;
  transition-property: color, box-shadow;
  transition-duration: 0.3s;
}

.dozent__tag a:hover {
  box-shadow: 0px 0px 0px 2px var(--black) inset;
  color: #000;
  transition-property: color, box-shadow;
  transition-duration: 0.3s;
}

.dozent__content {
  width: 100%;
}

@media (min-width: 680px) {
  .dozent__content {
    grid-column: 1 / 5;
  }
}

@media (min-width: 1000px) {
  .dozent__content {
    grid-column: 1 / 8;
  }
}
