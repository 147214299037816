.kurse-aside {
  background: dodgerblue;
  width: 100%;
  padding: 20px 20px 28px 20px;
  margin-bottom: var(--gap);
  background-color: var(--light-gray);
  color: white;
}

@media (min-width: 1000px) {
  .kurse-aside {
    max-width: 495px;
  }
}

.kurse-aside__text-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.kurse-aside__title {
  font-family: var(--title);
  font-size: var(--meta);
}
.kurse-aside__slogan {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  line-height: 1.3em;
  padding: 15% 0px;
  font-family: var(--title);
}
.kurse-aside__download {
  color: white;
  font-size: var(--meta);
  font-family: var(--title);
  border-bottom: 2px solid;
  padding-bottom: 0;
  transition: border-color 70ms ease-in-out, color 70ms ease-in-out;
}

.kurse-aside__download:hover {
  border-color: black;
  color: black;
}
