.kurse__stage {
  padding: 140px var(--padding-sides) 5px;
  max-width: 1700px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  white-space: nowrap;
}

@media (min-width: 680px) {
  .kurse__stage {
    padding: 170px var(--padding-sides) 5px;
  }
}

@media (min-width: 1000px) {
  .kurse__stage {
    padding: 200px var(--padding-sides) 10px;
  }
}

@media (min-width: 1440px) {
  .kurse__stage {
    padding: 200px var(--padding-sides) 10px;
  }
}

.kurse__title {
  font-size: var(--h1);
  font-family: var(--title);
  line-height: var(--h1-lh);
  hyphens: auto;
  -webkit-hyphens: auto;
}

@media (min-width: 680px) {
  .kurse__title {
    padding-right: 5%;
  }
}

.kurse__download-button {
  font-family: var(--title);
  font-size: 12px;
  white-space: initial;
  text-align: right;
  max-width: 112px;
  margin-bottom: 12px;
}

@media (min-width: 680px) {
  .kurse__download-button {
    font-size: 14px;
    max-width: 135px;
  }
}

.kurse__download-button-inner {
  border-bottom: 2px solid black;
}

.kurse__download-button:hover .kurse__download-button-inner {
  border-color: var(--color-hover);
}

.kurse__liste {
  padding: var(--padding);
  width: 100%;
  max-width: 1700px;
  margin: 0 auto;
  position: relative;
}

.kurse__error {
  padding: var(--padding);
  width: 100%;
  max-width: 1700px;
  margin: 0 auto;
  position: relative;
}
