@font-face {
  font-family: "Avenir Medium";
  src: url("../fonts/medium/avenir-medium.woff2") format("woff2"),
    url("../fonts/medium/avenir-medium.woff") format("woff");
}

@font-face {
  font-family: "Avenir Heavy";
  src: url("../fonts/heavy/avenir-heavy.woff2") format("woff2"),
    url("../fonts/heavy/avenir-heavy.woff") format("woff");
}
