/*
Theme Name: Lette-Akademie Berlin
Description: A custom theme for Lette-Akademie Berlin.
Version: 1.0
Author: Leslie Büttel
Author URI: https://lette-akademie.de
*/

@import "./assets/css/reset.css";
@import "./assets/css/fonts.css";
@import "./assets/css/constants.css";
@import "./assets/css/flickity-slider.css";
@import "./assets/css/globals.css";

.general__wrapper {
  padding: var(--padding);
  width: 100%;
  max-width: 1700px;
  margin: 0 auto;
  position: relative;
}

.general__wrapper-stage {
  padding: 140px var(--padding-sides) 5px;
  max-width: 1700px;
  margin: 0 auto;
}

@media (min-width: 680px) {
  .general__wrapper-stage {
    padding: 170px var(--padding-sides) 5px;
  }
}

@media (min-width: 1000px) {
  .general__wrapper-stage {
    padding: 200px var(--padding-sides) 10px;
  }
}

@media (min-width: 1440px) {
  .general__wrapper-stage {
    padding: 200px var(--padding-sides) 10px;
  }
}

.general__wrapper-stage--kurse {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  white-space: nowrap;
}

.general__wrapper--flex {
  display: block;
}

@media (min-width: 800px) {
  .general__wrapper--flex {
    display: grid;
    grid-template-columns: var(--columns);
    grid-column-gap: var(--gap);
  }
}

#general__page-title {
  font-size: var(--h1);
  font-family: var(--title);
  line-height: var(--h1-lh);
  hyphens: auto;
  -webkit-hyphens: auto;
}

@media (min-width: 680px) {
  #general__page-title {
    padding-right: 5%;
  }
}

.link-white {
  color: white;
  text-decoration: none;
  border-bottom: 2px solid white;
  font-weight: bold;
}

.link-white:hover {
  color: black;
  text-decoration: none;
  border-bottom: 2px solid black;
  font-weight: bold;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.link-underlined {
  text-decoration: none;
  border-bottom: 2px solid black;
  margin-top: 20px;
  display: inline-block;
}

.link-underlined:hover {
  color: var(--color-hover);
  border-bottom: 2px solid var(--color-hover);
}

#general__filter-wrapper {
  padding: var(--padding-top) var(--padding-sides) 0;
  position: relative;
  max-width: 1700px;
  margin: 0 auto;
}

.general__filter-alle {
  font-family: var(--title);
  font-size: 12px;
  cursor: pointer;
  margin: 0 0 0 20px;
  display: inline-block;
  border-bottom: 2px solid;
  position: relative;
  color: #d4d4d4;
  bottom: 3px;
}

@media (min-width: 680px) {
  #general__filter-toggle::after {
    font-size: 14px;
  }
}

.general__filter-alle--black {
  color: black;
}

.general__filter-alle--black:hover {
  color: salmon;
}

#general__filter-toggle {
  font-family: var(--title);
  font-size: var(--meta);
  position: relative;
  cursor: pointer;
  padding-right: 25px;
  display: inline-block;
}

#general__filter-toggle::after {
  content: "";
  width: 8px;
  height: 8px;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
  transform: rotate(45deg);
  right: 5px;
  bottom: 7px;
  position: absolute;
  transition: transform 100ms ease-in-out;
}

.general__filter--visibility #general__filter-toggle::after {
  transform: rotate(-135deg);
  bottom: 3px;
}

@media (min-width: 1000px) {
  #general__filter-toggle::after {
    transform: rotate(-135deg);
  }

  .general__filter--visibility #general__filter-toggle::after {
    transform: rotate(45deg);
  }
}

@media (min-width: 680px) {
  #general__filter-toggle::after {
    bottom: 7px;
  }

  .general__filter--visibility #general__filter-toggle::after {
    bottom: 2px;
  }
}

@media (min-width: 1000px) {
  #general__filter-toggle::after {
    bottom: 2px;
  }

  .general__filter--visibility #general__filter-toggle::after {
    bottom: 8px;
    width: 9px;
    height: 9px;
  }
}

.general__filter-inner-wrapper {
  display: none;
}

.general__filter--visibility .general__filter-inner-wrapper {
  display: block;
  margin-top: 15px;
}

@media (min-width: 1000px) {
  .general__filter-inner-wrapper {
    display: block;
    margin-top: 15px;
  }

  .general__filter--visibility .general__filter-inner-wrapper {
    display: none;
  }
}

.general__filter {
  padding-bottom: 4px;
  display: flex;
}

.general__filter-label {
  font-family: var(--title);
  font-size: var(--meta-s);

  padding-bottom: 10px;
  display: block;
  margin: 0px 10px 10px 0px;
  padding: 7px 0 7px;
}

.general__filter-tags-wrapper {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 1000px) {
  .general__filter-tags-wrapper {
    max-width: 90%;
  }
}

.general__filter-tag {
  border-radius: 5px;
  box-shadow: 0px 0px 0px 2px var(--light-gray) inset;
  cursor: pointer;
  display: inline-block;
  font-family: var(--title);
  font-size: var(--meta-s);
  margin: 0px 10px 10px 0px;
  padding: 7px 10px 6px;
  transition-property: color, box-shadow;
  transition-duration: 0.3s;
}

.general__filter-tag a {
  line-height: var(--meta-lh);
}

.general__filter-tag:hover {
  color: #000;
  box-shadow: 0px 0px 0px 2px var(--black) inset;
  transition-property: color, box-shadow;
  transition-duration: 0.3s;
}

.general__filter-tag--selected {
  border-radius: 5px;
  box-shadow: 0px 0px 0px 2px var(--black) inset;
  cursor: pointer;
  color: #000;
  display: inline-block;
  font-family: var(--title);
  font-size: var(--meta-s);
  margin: 0px 10px 10px 0px;
  padding: 7px 10px 6px;
  transition-property: color, box-shadow;
  transition-duration: 0.3s;
}

.general__white-button {
  border-radius: 5px;
  border: 2px solid black;
  background: none;
  cursor: pointer;
  display: inline-block;
  font-family: var(--title);
  font-size: var(--meta);
  letter-spacing: 0.05rem;
  padding: 11px 15px 10px;
  text-align: center;
  width: 100%;
  max-width: 350px;
  transition: border-color 150ms ease-in-out;
}

.general__green-button {
  border-radius: 5px;
  border: none;
  background: none;
  cursor: pointer;
  color: white;
  display: inline-block;
  font-family: var(--title);
  font-size: var(--meta);
  letter-spacing: 0.05rem;
  padding: 13px 17px 12px;
  text-align: center;
  width: 100%;
  /* max-width: 350px; */
  background: #64d656;
  transition: 150ms ease-in-out;
}

@media (min-width: 1000px) {
  .general__green-button {
    max-width: unset;
  }
}

.general__white-link {
  border-radius: 5px;
  border: 2px solid black;
  background: none;
  cursor: pointer;
  display: inline-block;
  font-family: var(--title);
  font-size: var(--meta);
  letter-spacing: 0.05rem;
  padding: 14px 15px 12px;
  text-align: center;
  width: 100%;
  max-width: 350px;
  transition: border-color 150ms ease-in-out;
}

.general__header-link {
  border-radius: 5px;
  border: 2px solid white;
  color: white;
  background: none;
  cursor: pointer;
  display: inline-block;
  font-family: var(--title);
  font-size: var(--meta);
  letter-spacing: 0.05rem;
  padding: 14px 40px 12px;
  text-align: center;
  width: 100%;
  max-width: 350px;
  transition: color 150ms ease-in-out, border-color 150ms ease-in-out;
}

.general__header-link:hover {
  border-color: black;
  color: black;
}

.cookie-banner__button,
.general__white-button:hover,
.general__white-link:hover {
  border-color: #a7a7a7;
}

.general__green-button:hover {
  background: #6fea60;
}

.cookie-banner__button {
  border-radius: 5px;
  border: 1px solid white;
  color: white;
  background: none;
  cursor: pointer;
  display: inline-block;
  font-family: var(--title);
  font-size: 14px;
  letter-spacing: 0.05rem;
  padding: 11px 15px 10px;
  text-align: center;
  transition: border-color 150ms ease-in-out;
  width: 80px;
}

.cookie-banner__text p {
  width: 100%;
  padding-right: 0;
  padding-bottom: 20px;
  font-size: 14px;
}

@media (min-width: 500px) {
  .cookie-banner__text p {
    width: 75%;
    padding-right: 20px;
    padding-bottom: 0;
  }
}

@media (min-width: 1000px) {
  .cookie-banner__text p {
    width: 100%;
  }
}

.cookie-banner__text p a {
  color: white;
  text-decoration: underline;
}
/* HOME */
.home__main-text {
  font-size: var(--h3);
  line-height: var(--h3-lh);
  font-family: var(--title);
  text-align: center;
  color: white;
  margin: 0 auto;
  padding: var(--padding);
}

@media (min-width: 680px) {
  .home__main-text {
    max-width: 1300px;
  }
}

#home__lead {
  font-size: var(--h4);
  line-height: var(--h4-lh);
  font-family: var(--font);
  text-align: center;
  margin: 10px auto 0px auto;
}

@media (min-width: 680px) {
  #home__lead {
    margin: 60px auto 0px auto;
    max-width: 80%;
  }
}

@media (min-width: 1000px) {
  #home__lead {
    margin: 60px auto 0px auto;
    max-width: 900px;
  }
}

#home__lead a {
  text-decoration: underline;
}

#home__header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  min-height: 55vh;
  padding-bottom: 33px;
}

@media (min-width: 680px) {
  #home__header {
    min-height: 550px;
    min-height: 85vh;
  }
}

.home__header-inner-wrapper {
  padding: 40px var(--padding-sides);
  width: 100%;
  max-width: 1700px;
  margin: 0 auto;
  position: static;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

@media (min-width: 680px) {
  .home__header-inner-wrapper {
    position: absolute;
  }
}

.home__header-quote {
  font-size: 100px;
  line-height: 1.2em;
  color: white;
  font-family: var(--title);
  margin-top: 90px;
}

@media (min-width: 680px) {
  .home__header-quote {
    font-size: 200px;
    margin-top: 45px;
  }
}

@media (min-width: 1000px) {
  .home__header-quote {
    font-size: 250px;
  }
}

.home__header-meta {
  position: relative;
  bottom: 0;
  width: 100%;
  /* padding: 0 var(--padding-sides) 25px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 650px) {
  .home__header-meta {
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
  }
}

.home__header-meta-fact {
  color: white;
  font-size: var(--meta);
  font-family: var(--title);
  line-height: var(--meta-lh);
  text-align: center;
}

@media (min-width: 650px) {
  .home__header-meta-fact {
    text-align: left;
  }
}

.home__header-meta-link {
  color: white;
  font-size: var(--meta);
  font-family: var(--title);
  line-height: var(--meta-lh);
  border-bottom: 2px solid;
  padding-bottom: 0;
  margin-top: 20px;
  transition: border-color 70ms ease-in-out, color 70ms ease-in-out;
}

.home__header-meta-link:hover {
  border-color: black;
  color: black;
}

.home__programm-download {
  display: none;
}

@media (min-width: 800px) {
  .home__programm-download {
    position: absolute;
    bottom: 35px;
    right: 40px;
    font-family: var(--title);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
    font-size: 0.8rem;
    line-height: 1.3em;
  }
}

.home__programm-download:hover {
  opacity: 0.7;
}

.home__programm-download img {
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
}

.home__meta-title {
  font-family: var(--title);
  font-size: 1rem;
  line-height: 1.3em;
  margin-bottom: 15px;
  display: block;
}

#home__highlights-wrapper {
  display: grid;
  grid-gap: var(--gap);
  grid-template-columns: var(--columns);
}

.home__highlights-item {
  grid-column: 1/-1;
  padding: 20px 20px 28px 20px;
  display: block;
  box-sizing: border-box;
  background-color: var(--light-gray);
  position: relative;
  width: 100vw;
  left: -20px;
}

@media (min-width: 650px) {
  .home__highlights-item {
    display: flex;
    align-items: center;
    width: 100%;
    left: 0;
  }
}

@media (min-width: 1000px) {
  .home__highlights-item {
    display: block;
    align-items: unset;
  }
}

@media (min-width: 1000px) {
  .home__highlights-item--two {
    grid-column: span 6;
  }
  .home__highlights-item--three {
    grid-column: span 4;
  }
}

.home__highlights-image-wrapper {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  max-height: 90vw;
}

@media (min-width: 650px) {
  .home__highlights-image-wrapper {
    width: 50%;
    margin-bottom: 0;
    max-height: 35vw;
  }
}

@media (min-width: 1000px) {
  .home__highlights-image-wrapper {
    width: 100%;
    margin-bottom: 20px;
  }
}

.home__highlights-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home__highlights-description-wrapper {
  max-width: 490px;
}

@media (min-width: 650px) {
  .home__highlights-description-wrapper {
    width: 50%;
    margin-left: 24px;
  }
}

@media (min-width: 1000px) {
  .home__highlights-description-wrapper {
    width: 100%;
    margin-left: 0;
  }
}

.home__highlights-title {
  line-height: var(--liste-lh);
  font-family: var(--liste-font);
  font-size: var(--liste-titel);
  margin-bottom: 10px;
  hyphens: auto;
  -webkit-hyphens: auto;
}

@media (min-width: 680px) {
  .home__highlights-title {
    font-family: var(--title);
    font-size: var(--h4);
    line-height: var(--h4-lh);
    margin-bottom: 20px;
  }
}

@media (min-width: 1000px) {
  .home__highlights-title {
    margin-bottom: 15px;
  }
}

.home__highlights-description {
  font-size: 14px;
  line-height: var(--meta-lh);
  padding-bottom: 20px;
}

@media (min-width: 680px) {
  .home__highlights-description {
    font-size: 16px;
    line-height: var(--meta-lh);
  }
}

.home__highlights-link {
  border-bottom: 2px solid var(--link-grey);
  font-family: var(--title);
  font-size: 14px;
  cursor: pointer;
  transition: border-color 100ms ease-in-out;
}

@media (min-width: 680px) {
  .home__highlights-link {
    font-size: 16px;
  }
}

.home__highlights-link:hover {
  border-color: var(--color-hover);
}

.home__meta-label {
  font-size: var(--meta);
  font-family: var(--title);
  line-height: var(--meta-lh);
  margin-bottom: 15px;
  display: block;
}
/* DOZENTEN LISTE */

.dozenten-liste__title {
  line-height: var(--liste-lh);
  font-family: var(--liste-font);
  font-size: var(--liste-titel);
  grid-column: 1 / -2;
  padding-top: 5px;
}

@media (min-width: 680px) {
  .dozenten-liste__title {
    padding-top: 0px;
  }
}

.dozenten-liste__title a:hover {
  color: var(--color-hover);
}

/* DOZENTEN */
.dozenten__related-meta {
  font-size: var(--meta);
  font-family: var(--title);
  line-height: var(--meta-lh);
  margin-bottom: 15px;
  display: block;
}

.dozenten__facts-formatting {
  margin-bottom: 15px;
}

.dozenten__facts-formatting {
  font-size: var(--meta);
  font-family: var(--title);
  line-height: var(--meta-lh);
}

.dozenten__facts-formatting {
  font-size: var(--meta);
  line-height: var(--meta-lh);
}

.dozenten__facts-formatting p {
  color: black;
  font-size: var(--meta);
  font-family: var(--font);
}

.dozenten__facts-formatting a {
  color: black;
  text-decoration: underline;
  font-size: var(--meta);
  font-family: var(--font);
}

.dozenten__facts-formatting a:hover {
  color: var(--color-hover);
}

.dozenten__main {
  width: 100%;
}

@media (min-width: 680px) {
  .dozenten__main {
    grid-column: 1 / 5;
  }
}

@media (min-width: 1000px) {
  .dozenten__main {
    grid-column: 1 / 8;
  }
}

.dozenten__side-wrapper {
  width: 100%;
  position: relative;
}

@media (min-width: 680px) {
  .dozenten__side-wrapper {
    padding-left: 30px;
    grid-column: 5 / -1;
  }
}

@media (min-width: 1000px) {
  .dozenten__side-wrapper {
    grid-column: 8 / -1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

.dozenten__side-element {
  width: 100vw;
  position: relative;
  padding: 20px;
  margin-bottom: 40px;
  background: var(--light-gray);
  left: -20px;
  top: 0;
  display: flex;
  flex-direction: column;
}

@media (min-width: 680px) {
  .dozenten__side-element {
    width: 100%;
    left: 0;
    max-width: 495px;
  }
}

.dozenten__subheading,
.dozenten__side-element-heading {
  font-size: var(--h3);
  font-family: var(--title);
  line-height: var(--h3-lh);
  padding-bottom: 25px;
}

.dozenten__profilbild {
  width: 100%;
  max-width: 350px;
  height: auto;
  align-self: center;
}

@media (min-width: 680px) {
  .dozenten__profilbild {
    max-width: unset;
  }
}

.dozenten__profilbild-caption {
  font-size: 12px;
  line-height: var(--meta-lh);
  color: rgb(114, 114, 114);
  position: relative;
  top: 9px;
}

.dozenten__underlined-link {
  color: black;
  font-size: var(--meta);
  font-family: var(--title);
  line-height: var(--meta-lh);
  border-bottom: 2px solid black;
  margin-top: 50px;
  transition: border-color 70ms ease-in-out, color 70ms ease-in-out;
}

.dozenten__underlined-link:hover {
  border-color: var(--color-hover);
}

.dozenten__wrapper--flex {
  display: flex;
  flex-direction: column-reverse;
}

@media (min-width: 680px) {
  .dozenten__wrapper--flex {
    display: grid;
    grid-template-columns: var(--columns);
    grid-column-gap: var(--gap);
  }
}

/* GALLERY */
.main-carousel {
  width: calc(100vw - 40px);
  position: relative;
  background: var(--light-gray);
  padding: 5px;
}

@media (min-width: 650px) {
  .main-carousel {
    width: unset;
    padding: 20px;
  }
}

.carousel-cell {
  display: flex;
  flex-direction: column;
  padding-right: 5px;
}

@media (min-width: 650px) {
  .carousel-cell {
    padding-right: 20px;
  }
}

.carousel-cell__image {
  height: 40vh;
}

@media (min-width: 1000px) {
  .carousel-cell__image {
    height: 70vh;
  }
}

.carousel-cell__image-impressions {
  height: 40vh;
}

@media (min-width: 1000px) {
  .carousel-cell__image-impressions {
    height: 55vh;
  }
}

.carousel-cell__image img,
.carousel-cell__image-impressions img {
  height: 100%;
  width: auto;
}

.flickity-page-dots {
  margin: 0 -5px;
}

@media (min-width: 650px) {
  .flickity-page-dots {
    margin: 0 -20px;
  }
}

.flickity-page-dots .dot {
  width: 7px;
  height: 7px;
  margin: 0 5px;
}

@media (min-width: 800px) {
  .flickity-page-dots .dot {
    width: 10px;
    height: 10px;
    margin: 0 8px;
  }
}
.gallery-item {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-carousel__caption {
  font-size: 12px;
  max-width: 350px;
  margin-top: 6px;
  color: rgb(114, 114, 114);
}

@media (min-width: 650px) {
  .main-carousel__caption {
    margin-top: 12px;
  }
}
/* KURSE */

.kurse__main {
  width: 100%;
}

@media (min-width: 800px) {
  .kurse__main {
    grid-column: 1 / 5;
  }
}

@media (min-width: 1000px) {
  .kurse__main {
    grid-column: 1 / 7;
  }
}

@media (min-width: 1000px) {
  .kurse__main {
    grid-column: 1 / 8;
  }
}

.kurse__side-wrapper {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 100;
}

@media (min-width: 800px) {
  .kurse__side-wrapper {
    padding-left: 30px;
    left: unset;
    position: relative;
    grid-column: 5 / -1;
  }
}

@media (min-width: 1000px) {
  .kurse__side-wrapper {
    grid-column: 8 / -1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

.kurse_side-element-inner-wrapper {
  display: none;
}

@media (min-width: 800px) {
  .kurse_side-element-inner-wrapper {
    display: block;
  }
}

.kurse__stycky-element {
  width: 100%;
  padding: 0;
  border: none;
}

@media (min-width: 800px) {
  .kurse__stycky-element {
    width: 100%;
    padding: 20px;
    border: 2px solid black;
    position: -webkit-sticky;
    position: sticky;
    top: calc(var(--padding-top-desktop) + var(--padding-bottom-desktop));
  }
}

@media (min-width: 1000px) {
  .kurse__stycky-element {
    padding: 30px;
    max-width: 495px;
  }
}

.kurse__stycky-element-heading {
  hyphens: auto;
  -webkit-hyphens: auto;
}

.kurse__subheading,
.kurse__stycky-element-heading,
.kurse__feedback-heading {
  font-size: var(--h3);
  font-family: var(--title);
  line-height: var(--h3-lh);
  padding-bottom: 25px;
}

.kurse__heading-anmeldung {
  font-size: var(--h3);
  font-family: var(--title);
  line-height: var(--h3-lh);
  max-width: calc(100% - 45px);
}

.kurse__heading-anmeldung-wrapper {
  margin-bottom: 15px;
}

@media (min-width: 1000px) {
  .kurse__heading-anmeldung-wrapper {
    margin-bottom: 25px;
  }
}

.kurse__meta-items {
  margin-bottom: 15px;
}

@media (min-width: 1200px) {
  .kurse__main .kurse__meta-items {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: var(--gap);
  }
}

.kurse__meta-items:last-child {
  margin-bottom: 0;
}

.kurse__meta-title {
  font-size: var(--meta);
  font-family: var(--title);
  line-height: var(--meta-lh);
}

@media (min-width: 1200px) {
  .kurse__main .kurse__meta-title {
    grid-column: 1 / 3;
  }
}

.kurse__meta-description {
  font-size: var(--meta);
  line-height: var(--meta-lh);
}

@media (min-width: 1200px) {
  .kurse__main .kurse__meta-description {
    grid-column: 3/ -1;
  }
}

.kurse__meta-link {
  color: black;
  text-decoration: underline;
  margin-right: 10px;
  display: inline-block;
}

.kurse__meta-link:hover {
  color: var(--color-hover);
}

.kurse__related-meta {
  font-size: var(--meta);
  font-family: var(--title);
  line-height: var(--meta-lh);
  margin-bottom: 15px;
  display: block;
}

#kurse__signup-form-overlay-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  transition: transform 0.6s ease-in-out, background-color 2s ease-in-out;
  -webkit-overflow-scrolling: touch;
}

.kurse__signup-form-overlay {
  padding: var(--padding);
  top: 0;
  width: 100%;
  position: absolute;
  background-color: white;
}

@media (min-width: 800px) {
  .kurse__signup-form-overlay {
    top: 80px;
    width: 80%;
    max-width: calc(1400px - 20%);
  }
}

@media (min-width: 1000px) {
  .kurse__signup-form-overlay {
    padding-top: 65px;
  }
}

#kurse__signup-form {
  position: relative;
}

.kurse__signup-form--hidden {
  transform: translateY(1000%);
  background-color: rgba(220, 220, 220, 0);
}

.kurse__signup-form--visible {
  transform: translateY(0);
  background-color: rgba(220, 220, 220, 0.7);
}

.kurse__signup-button-wrapper {
  display: flex;
  justify-content: center;
  background: white;
  padding: 15px 0;
}

@media (min-width: 800px) {
  .kurse__signup-button-wrapper {
    position: relative;
    bottom: unset;
    left: unset;
    right: unset;
    padding: 25px 0 0;
    box-shadow: none;
    background: none;
  }
}

.kurse__bildungsurlaub {
  padding-bottom: 25px;
  font-family: var(--title);
}

@media (min-width: 1000px) {
  .kurse__bildungsurlaub {
    padding-bottom: 40px;
  }
}

.kurse__filter {
  padding-top: 40px;
}

.kurse__feedback {
  margin-bottom: 20px;
}

.kurse__feedback:last-child {
  margin-bottom: 0;
}

.kurse__bildungsurlaub-titel {
  font-size: var(--h4);
  line-height: var(--h4-ls);
  font-family: var(--title);
  margin: 20px 0 0;
}

@media (min-width: 680px) {
  .kurse__bildungsurlaub-titel {
    margin: 25px 0 0;
  }
}

@media (min-width: 1000px) {
  .kurse__bildungsurlaub-titel {
    margin: 40px 0 0;
  }
}

.kurse__anmeldung-zusatz {
  margin-bottom: 0px;
}

@media (min-width: 1000px) {
  .kurse__anmeldung-zusatz {
    margin-bottom: 30px;
  }
}
/* KURSE LISTE */

.kurse-liste__date {
  font-size: var(--meta);
  line-height: var(--meta-lh);
  font-family: var(--title);
  white-space: nowrap;
}

.kurse-liste__date--single {
  padding-top: 20px;
}

@media (min-width: 1000px) {
  .kurse-liste__date--single {
    padding-top: 26px;
  }
}

/* PAGE */
.page__content {
  font-size: 1rem;
  line-height: 1.3em;
  margin-bottom: 50px;
  width: 100%;
}

@media (min-width: 680px) {
  .page__content {
    font-size: 1.25rem;
    padding-right: 40px;
    width: 66%;
  }
}

@media (min-width: 1000px) {
  .page__content {
    font-size: 1.25rem;
    padding-right: 100px;
  }
}

.page__content img {
  height: 100%;
}

.page__content .img-wrap {
  width: 100%;
  height: 300px;
  margin: 0px 0px 40px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page__content h2 {
  font-size: 1.5rem;
  line-height: 1.25em;
  margin-bottom: 40px;
}

@media (min-width: 680px) {
  .page__content h2 {
    font-size: 1.75rem;
  }
}

@media (min-width: 1000px) {
  .page__content h2 {
    font-size: 2.2rem;
  }
}

.page__content p,
.page__content ul,
.page__content ol {
  padding-bottom: 1.3em;
}

.page__content ol li {
  list-style: decimal;
  margin-left: 20px;
}

.page__content ul li {
  list-style: disc;
  margin-left: 20px;
}

.page__side-wrapper {
  width: 100vw;
  left: -20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

@media (min-width: 680px) {
  .page__side-wrapper {
    padding-left: 30px;
    grid-column: 5 / -1;
    left: 0;
    width: unset;
  }
}

@media (min-width: 1000px) {
  .page__side-wrapper {
    grid-column: 8 / -1;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

.page__side-wrapper .add-info-item {
  width: 100%;
}

.programm-download {
  background: salmon;
  color: white;
}

.page__side-download-slogan {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  line-height: 1.3em;
  padding: 15% 0px;
  font-family: var(--title);
}

.page__side-download-title-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.page__side-download-title {
  font-family: var(--title);
  font-size: var(--meta);
}

.page__download-link {
  color: white;
  font-size: var(--meta);
  font-family: var(--title);
  border-bottom: 2px solid;
  padding-bottom: 0;
  transition: border-color 70ms ease-in-out, color 70ms ease-in-out;
}

.page__download-link:hover {
  border-color: black;
  color: black;
}

.wp-block-image {
  width: 100%;
  padding: 60px;
  display: flex;
  justify-content: center;
}

.wp-block-image img {
  width: 50%;
  min-width: 300px;
}

.page__side-elem-title {
  font-weight: bold;
  font-size: 2em;
  line-height: 1.4;
}

/* CONTENT */

.content-formatting {
  font-size: var(--p);
  line-height: var(--p-lh);
  margin-bottom: 20px;
}

@media (min-width: 650px) {
  .content-formatting {
    margin-bottom: 40px;
  }
}

.content-formatting strong {
  font-family: var(--title);
}

.content-formatting h2 {
  font-size: var(--h3);
  line-height: var(--h3-ls);
  margin: 20px 0 25px;
}

.content-formatting p,
.content-formatting ul,
.content-formatting ol {
  padding-bottom: 25px;
  font-size: var(--p);
  line-height: var(--p-lh);
}

@media (min-width: 1000px) {
  .content-formatting p,
  .content-formatting ul,
  .content-formatting ol {
    padding-bottom: 40px;
  }
}

.content-formatting ol li {
  list-style: decimal;
  margin-left: 20px;
  font-size: var(--p);
  line-height: var(--p-lh);
}

.content-formatting ul li {
  list-style: disc;
  margin-left: 20px;
  font-size: var(--p);
  line-height: var(--p-lh);
}

.wp-block-image {
  width: 100%;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (min-width: 650px) {
  .wp-block-image {
    padding: 60px 0;
  }
}

.wp-block-image img {
  width: 100%;
  height: 100%;
  min-width: unset;
  max-width: 350px;
}

div.wp-caption {
  width: auto !important;
}

.content-formatting .img-wrap {
  width: 100%;
  height: 300px;
  margin: 0px 0px 40px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-formatting a {
  color: black;
  text-decoration: underline;
}

p.wp-caption-text {
  font-size: 14px;
  line-height: var(--meta-lh);
  color: #bbb;
  padding-bottom: 0;
  padding-top: 4px;
}

/* WP FORMS */
.wpforms-field-container {
  margin: 20px 0;
  font-size: var(--meta-s);
  display: block;
}

@media (min-width: 1000px) {
  .wpforms-field-container {
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: 1fr 1fr;
  }
}

.wpforms-field-container a {
  text-decoration: underline;
  color: black;
}

.wpforms-field-sublabel {
  display: none;
}

.wpforms-field-label {
  font-size: var(--meta-s);
  font-family: var(--title);
}

.wpforms-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .wpforms-field:last-child {
    grid-column: 1/-1;
  }
}

.label-input-wrap,
.label-radio-wrap {
  padding: 15px 0px 15px 10px;
  position: relative;
  width: 100%;
}

.wpforms-field-container input {
  width: 100%;
  height: 40px;
  font-size: 1rem;
  font-family: var(--title);
  color: black;
  border-style: solid;
  border: none;
  border-bottom: 2px solid black;
  padding: 0px 6px;
  background-color: var(--light-gray);
  margin-bottom: 12px;
  border-radius: 0;
}

.wpforms-field-container input::placeholder {
  color: #d2d2d2;
  font-size: var(--meta-s);
}

.choice-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.wpforms-field-container input[type="checkbox"],
.wpforms-field-container input[type="radio"] {
  width: 30px;
  height: auto;
  margin-top: 8px;
}

.wpforms-field.wpforms-field-hp {
  display: none;
}

.wpforms-submit {
  border-radius: 5px;
  border: none;
  background: none;
  cursor: pointer;
  color: white;
  display: inline-block;
  font-family: inherit;
  font-weight: 400;
  font-size: var(--meta);
  letter-spacing: 0.05rem;
  padding: 13px 17px 12px;
  text-align: center;
  width: 100%;
  max-width: 350px;
  background: #64d656;
  transition: background 150ms ease-in-out;
}

.wpforms-submit:hover {
  background: #6fea60;
}

.wpforms-submit-container {
  display: flex;
  justify-content: center;
}

.agb-form {
  grid-column: 1 / -1;
}

.agb-form .wpforms-field-description {
  order: 1;
  margin: 30px 0;
  font-size: var(--p);
  line-height: var(--p-lh);
}

@media (min-width: 680px) {
  .agb-form .wpforms-field-description {
    margin: 60px 0;
  }
}

.agb-form .wpforms-field-label {
  order: 2;
}

.agb-form .wpforms-field-required {
  order: 3;
}

.ermaessigt {
  grid-column: 1 / -1;
}

.no-margin-form-field {
  margin: 0;
}

.full-width-form-field {
  grid-column: 1 / -1;
}

.flex-form-field,
.flex-form-field ul {
  display: flex;
}

.flex-form-field li {
  padding-right: 20px;
}

.form-field-headline {
  font-size: var(--meta);
  font-family: var(--title);
  line-height: var(--meta-lh);
  margin-top: 30px;
}

.form-field-hinweis {
  margin: 30px 0;
  font-size: var(--p);
  line-height: var(--p-lh);
}

.agb-form-field ul li {
  display: flex;
}

.agb-form-field input[type="checkbox"] {
  flex-shrink: 0;
}

.form-field-upload input {
  padding-top: 8px;
}

.form-field-half-width {
  width: 40%;
}

.form-field-text textarea {
  outline: none;
  line-height: 1.4;
  resize: none;
  border: 2px solid #e7e7e7;
  background-color: var(--light-gray);
  height: 12em;
}

/* MAIN NAV */
#la-main-nav {
  position: absolute;
  z-index: 1;
  width: 100%;
}

#la-main-nav__inner {
  padding: var(--padding);
  display: flex;
  justify-content: space-between;
  max-width: 1700px;
  margin: 0 auto;
}

#la-main-nav__lette-logo {
  width: 70px;
  height: auto;
}

a #la-main-nav__lette-logo {
  line-height: 0;
}

@media (min-width: 680px) {
  #la-main-nav__lette-logo {
    width: 85px;
  }
}

@media (min-width: 1000px) {
  #la-main-nav__lette-logo {
    width: 100px;
  }
}

.la-main-nav__lette-logo-img {
  width: 100%;
  height: auto;
}
/* BURGER NAV */

.burger-bar,
.burger-bar::before,
.burger-bar::after {
  width: 100%;
  height: 3px;
  background-color: black;
}

@media (min-width: 680px) {
  .burger-bar,
  .burger-bar::before,
  .burger-bar::after {
    height: 4px;
  }
}

.burger-bar::before {
  position: absolute;
  top: 0px;
  content: "";
}

.burger-bar::after {
  position: absolute;
  bottom: 0px;
  content: "";
}

.burger-nav-open-items:first-child {
  border-top: 2px solid black;
}

.burger-nav-open-items {
  border-bottom: 2px solid black;
  font-size: var(--h3);
  font-family: var(--title);
  line-height: var(--h3-lh);
  position: relative;
}

.burger-nav-open-items a {
  text-decoration: none;
  color: var(--black);
  display: block;
  padding: 10px 0;
  transition-property: color;
  transition-duration: 0.1s;
}

.burger-nav-open-items a:hover {
  color: var(--color-hover);
  transition-property: color;
  transition-duration: 0.1s;
}

/* SENDINBLUE NEWSLETTER */

.custom-select {
  grid-column: 1 / -1;
}
.custom-select select {
  display: block;
  font-size: 14px;
  font-family: inherit;
  font-weight: 400;
  color: inherit;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: none;
  border-bottom: 2px solid #000;
  box-shadow: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--light-gray);
  background-image: url("./assets/images/arrow-down.png");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1.25em auto, 100%;
  background-blend-mode: multiply;
  margin: 0.5rem 0 1rem;
}

@media (min-width: 1000px) {
  .custom-select select {
    font-size: 16px;
  }
}

.custom-select select::-ms-expand {
  display: none;
}

.custom-select select:hover {
  border-color: #888;
}

.custom-select select:focus {
  border-color: #aaa;
  color: #222;
  outline: none;
}

/* NEW CSS */

@import "./assets/css/dozenten.css";
@import "./assets/css/footer.css";
@import "./assets/css/kurse.css";
@import "./assets/css/page.css";
@import "./assets/css/template-parts/stage.css";
@import "./assets/css/template-parts/text.css";
@import "./assets/css/template-parts/centered-lead.css";
@import "./assets/css/template-parts/upcoming-courses.css";
@import "./assets/css/template-parts/button.css";
@import "./assets/css/template-parts/dozenten-liste.css";
@import "./assets/css/template-parts/dozent.css";
@import "./assets/css/template-parts/filter-options.css";
@import "./assets/css/template-parts/tag.css";
@import "./assets/css/template-parts/kurse-liste.css";
@import "./assets/css/template-parts/header-navigation.css";
@import "./assets/css/template-parts/highlight-aside.css";
@import "./assets/css/template-parts/kurse-aside.css";
@import "./assets/css/template-parts/cookie-banner.css";
@import "./assets/css/template-parts/newsletter.css";
@import "./assets/css/template-parts/kurs.css";
