.text {
  font-size: var(--p);
  line-height: var(--p-lh);
  margin-bottom: 20px;
}

@media (min-width: 650px) {
  .text {
    margin-bottom: 40px;
  }
}

.text strong {
  font-family: var(--title);
}

.text h2 {
  font-size: var(--h3);
  line-height: var(--h3-ls);
  margin: 20px 0 25px;
}

.text p,
.text ul,
.text ol {
  padding-bottom: 25px;
  font-size: var(--p);
  line-height: var(--p-lh);
}

@media (min-width: 1000px) {
  .text p,
  .text ul,
  .text ol {
    padding-bottom: 40px;
  }
}

.text ol li {
  list-style: decimal;
  margin-left: 20px;
  font-size: var(--p);
  line-height: var(--p-lh);
}

.text ul li {
  list-style: disc;
  margin-left: 20px;
  font-size: var(--p);
  line-height: var(--p-lh);
}

.wp-block-image {
  width: 100%;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (min-width: 650px) {
  .wp-block-image {
    padding: 60px 0;
  }
}

.wp-block-image img {
  width: 100%;
  height: 100%;
  min-width: unset;
  max-width: 350px;
}

div.wp-caption {
  width: auto !important;
}

.text .img-wrap {
  width: 100%;
  height: 300px;
  margin: 0px 0px 40px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text a {
  color: black;
  text-decoration: underline;
}

p.wp-caption-text {
  font-size: 14px;
  line-height: var(--meta-lh);
  color: #bbb;
  padding-bottom: 0;
  padding-top: 4px;
}

.text img {
  width: 100%;
  object-fit: contain;
  max-height: 50vw;
}
