:root {
  /* Colors */
  --font-color: black;
  --light-gray: #f7f7f7;
  --black: #000;
  --color-hover: royalblue;
  --dark-grey: #4b4646;
  --link-grey: #e4e4e4;

  /* Font Families */
  --font: "Avenir Medium", sans-serif;
  --title: "Avenir Heavy", sans-serif;

  /* Font sizes */
  /* 14px 16px 24px 40px 64px 104px 168px ... */
  /* 0.75 1    1.5  2.5  4    6.5   10.5  ... */
  --p-s: 1rem;
  --p-m: 1rem;
  --p-l: 1.25rem;
  --p-xl: 1.5rem;
  --p: var(--p-s);

  --p-lh-s: 1.3;
  --p-lh-m: 1.3;
  --p-lh-l: 1.4;
  --p-lh: var(--p-lh-s);

  --h1-xs: 2rem;
  --h1-s: 2.5rem;
  --h1-m: 4rem;
  --h1-l: 6.5rem;
  --h1: var(--h1-xs);

  --h1-lh-s: 1.2;
  --h1-lh-m: 1.2;
  --h1-lh-l: 1.05;
  --h1-lh: var(--h1-lh-s);

  --h3-s: 1.5rem;
  --h3-m: 2rem;
  --h3-l: 2.5rem;
  --h3: var(--h3-s);

  --h3-lh-s: 1.175;
  --h3-lh-m: 1.2;
  --h3-lh-l: 1.25;
  --h3-lh: var(--h3-lh-s);

  --h4-s: 1.25rem;
  --h4-m: 1.5rem;
  --h4-l: 2rem;
  --h4: var(--h4-s);

  --h4-lh-s: 1.1;
  --h4-lh-m: 1.2;
  --h4-lh-l: 1.25;
  --h4-lh: var(--h4-lh-s);

  --liste-titel-s: 1.5rem;
  --liste-titel-m: 2.5rem;
  --liste-titel-l: 4rem;
  --liste-titel: var(--liste-titel-s);

  --liste-lh-s: 1.2;
  --liste-lh-m: 1.2;
  --liste-lh-l: 1.2;
  --liste-lh: var(--liste-lh-s);

  --liste-font: var(--title);

  --meta-s: 0.875rem;
  --meta-m: 1rem;
  --meta-l: 1.125rem;
  --meta-xl: 1.25rem;
  --meta: var(--meta-s);

  --meta-lh: var(--p-lh);

  /* Spacing */
  --padding-top-mobile: 20px;
  --padding-bottom-mobile: 40px;
  --padding-sides-mobile: 20px;

  /* Breakpoint: 650px */
  --padding-top-tablet: 30px;
  --padding-bottom-tablet: 60px;
  --padding-sides-tablet: 40px;

  /* Breakpoint: 1000px */
  --padding-top-desktop: 30px;
  --padding-bottom-desktop: 60px;
  --padding-sides-desktop: 60px;

  /* Breakpoint: 1440px */
  --padding-top-desktop-xl: 30px;
  --padding-bottom-desktop-xl: 60px;
  --padding-sides-desktop-xl: 84px;

  --padding: var(--padding-top-mobile) var(--padding-sides-mobile)
    var(--padding-bottom-mobile);
  --padding-top: var(--padding-top-mobile);
  --padding-bottom: var(--padding-bottom-mobile);
  --padding-sides: var(--padding-sides-mobile);

  --gap-s: 12px;
  --gap-m: 12px;
  --gap-l: 24px;
  --gap: var(--gap-s);

  --columns-s: repeat(6, 1fr);
  --columns-m: repeat(8, 1fr);
  --columns-l: repeat(12, 1fr);
  --columns: var(--columns-s);
}

@media (min-width: 400px) {
  :root {
    --h1: var(--h1-s);
    --h1-lh: var(--h1-lh-s);
  }
}

@media (min-width: 680px) {
  :root {
    --p: var(--p-m);
    --p-lh: var(--p-lh-m);

    --h1: var(--h1-m);
    --h1-lh: var(--h1-lh-m);

    --h3: var(--h3-m);
    --h3-lh: var(--h3-lh-m);

    --h4: var(--h4-m);
    --h4-lh: var(--h4-lh-m);

    --liste-titel: var(--liste-titel-m);
    --liste-lh: var(--liste-lh-m);

    --meta: var(--meta-m);
    --padding: var(--padding-top-tablet) var(--padding-sides-tablet)
      var(--padding-bottom-tablet);
    --padding-top: var(--padding-top-tablet);
    --padding-bottom: var(--padding-bottom-tablet);
    --padding-sides: var(--padding-sides-tablet);

    --gap: var(--gap-m);
    --columns: var(--columns-m);
  }
}

@media (min-width: 1000px) {
  :root {
    --p: var(--p-l);
    --p-lh: var(--p-lh-l);

    --h1: var(--h1-l);
    --h1-lh: var(--h1-lh-l);

    --h3: var(--h3-l);
    --h3-lh: var(--h3-lh-l);

    --h4: var(--h4-l);
    --h4-lh: var(--h4-lh-l);

    --liste-titel: var(--liste-titel-l);
    --liste-lh: var(--liste-lh-l);

    --meta: var(--meta-l);
    --padding: var(--padding-top-desktop) var(--padding-sides-desktop)
      var(--padding-bottom-desktop);
    --padding-top: var(--padding-top-desktop);
    --padding-bottom: var(--padding-bottom-desktop);
    --padding-sides: var(--padding-sides-desktop);

    --gap: var(--gap-l);
    --columns: var(--columns-l);
  }
}

@media (min-width: 1440px) {
  :root {
    --p: var(--p-xl);

    --padding: var(--padding-top-desktop-xl) var(--padding-sides-desktop-xl)
      var(--padding-bottom-desktop-xl);
    --padding-top: var(--padding-top-desktop-xl);
    --padding-bottom: var(--padding-bottom-desktop-xl);
    --padding-sides: var(--padding-sides-desktop-xl);
    --meta: var(--meta-xl);
  }
}
