.dozenten-liste__item {
  padding: 6px 0;
  border-bottom: 2px solid black;
  display: grid;
  grid-template-columns: var(--columns);
  grid-column-gap: var(--gap);
  padding: 15px 0px 13px;
  position: relative;
}

.dozenten-liste__item:first-child {
  border-top: 2px solid black;
}

.dozenten-liste__item:last-child {
  margin-bottom: 50px;
}
.dozenten-liste__arrow {
  grid-column: -2/-1;
  grid-row: 1 / 4;
  height: 100%;
  position: relative;
}

@media (min-width: 650px) {
  .dozenten-liste__arrow {
    grid-row: 1 / 3;
  }
}

.dozenten-liste__arrow::after {
  content: "";
  width: 15px;
  height: 15px;
  border-top: 3px solid black;
  border-right: 3px solid black;
  transform: rotate(45deg) translateY(63%);
  right: 5px;
  bottom: 50%;
  position: absolute;
}

@media (min-width: 650px) {
  .dozenten-liste__arrow::after {
    width: 25px;
    height: 25px;
  }
}

@media (min-width: 1000px) {
  .dozenten-liste__arrow::after {
    width: 30px;
    height: 30px;
    border-top: 4px solid;
    border-right: 4px solid;
  }
}

.dozenten-liste__arrow:hover {
  cursor: pointer;
}

.dozenten-liste__arrow:hover::after {
  cursor: pointer;
  border-color: var(--color-hover);
}

.dozenten-liste__filter {
  line-height: var(--meta-lh);
  font-size: var(--meta-s);
  grid-column: 1 / -2;
  margin-top: 8px;
}

.dozenten-liste__filter a {
  border-radius: 5px;
  box-shadow: 0px 0px 0px 1px var(--link-grey) inset;
  cursor: pointer;
  display: inline-block;
  font-family: var(--title);
  font-size: var(--meta-s);
  line-height: 2;
  margin: 0 10px 10px 0;
  padding: 4px 8px;
  transition-property: color, box-shadow;
  transition-duration: 0.3s;
}

.dozenten-liste__filter a:hover {
  box-shadow: 0px 0px 0px 2px var(--black) inset;
  color: #000;
  transition-property: color, box-shadow;
  transition-duration: 0.3s;
}
