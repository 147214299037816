.highlight-aside {
  width: 100%;
  padding: 20px 20px 28px 20px;
  margin-bottom: var(--gap);
  background-color: var(--light-gray);
}

@media (min-width: 1000px) {
  .highlight-aside {
    max-width: 495px;
  }
}

.highlight-aside__image {
  width: 100%;
  max-height: 350px;
  object-fit: cover;
}

.highlight-aside__title {
  line-height: var(--liste-lh);
  font-family: var(--liste-font);
  font-size: var(--liste-titel);
  margin-bottom: 10px;
  hyphens: auto;
  -webkit-hyphens: auto;
}

@media (min-width: 680px) {
  .highlight-aside__title {
    font-family: var(--title);
    font-size: var(--h4);
    line-height: var(--h4-lh);
    margin-bottom: 20px;
  }
}

@media (min-width: 1000px) {
  .highlight-aside__title {
    margin-bottom: 15px;
  }
}

.highlight-aside__description {
  font-size: 14px;
  line-height: var(--meta-lh);
  padding-bottom: 20px;
}

@media (min-width: 680px) {
  .highlight-aside__description {
    font-size: 16px;
    line-height: var(--meta-lh);
  }
}

.highlight-aside__link {
  border-bottom: 2px solid var(--link-grey);
  font-family: var(--title);
  font-size: 14px;
  cursor: pointer;
  transition: border-color 100ms ease-in-out;
}

@media (min-width: 680px) {
  .highlight-aside__link {
    font-size: 16px;
  }
}

.highlight-aside__link:hover {
  border-color: var(--color-hover);
}

.highlight-aside__image-link {
  display: block;
  margin-bottom: 18px;
}
