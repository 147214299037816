.footer {
  background-color: #3a3a3a;
}

.footer__inner {
  margin: 0 auto;
  max-width: 1700px;
  padding: 30px var(--padding-sides);
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.8rem;
  letter-spacing: 0.6px;
}

@media (min-width: 680px) {
  .footer__inner {
    margin: 0 auto;
    max-width: 1700px;
    padding: 30px var(--padding-sides);
    display: grid;
    grid-template-columns: var(--columns);
    grid-column-gap: var(--gap);
    color: white;
    font-size: 0.8rem;
    letter-spacing: 0.6px;
  }
}

.footer__clara {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  grid-column: span 6;
  grid-row: span 2;
}

.footer__clara-logo {
  width: 50%;
}

.footer__clara-logo-image {
  width: 150px;
  height: auto;
  display: block;
  margin-right: 20px;
}

.footer__clara-text {
  padding-top: 12px;
}

.footer__menu {
  width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 680px) {
  .footer__menu {
    grid-column: -3 / -1;
    justify-self: center;
    align-self: end;
    margin-bottom: 0;
  }
}

@media (min-width: 1000px) {
  .footer__menu {
    grid-column: -4 / -1;
  }
}

.footer__nav {
  text-align: center;
  line-height: var(--meta-lh);
  grid-column: span 6;
  margin-bottom: 30px;
}

@media (min-width: 680px) {
  .footer__nav {
    text-align: right;
  }
}

.footer__nav a {
  color: white;
  font-family: var(--font);
}

.footer__nav a:hover {
  color: var(--color-hover);
  font-family: var(--font);
}

.footer__social {
  display: flex;
  justify-content: center;
}

@media (min-width: 680px) {
  .footer__social {
    justify-content: flex-end;
  }
}

.footer__social-icon {
  background: rgb(87, 87, 87);
  border-radius: 50%;
  margin: 0 5px;
}

@media (min-width: 680px) {
  .footer__social-icon {
    margin: 0 0 0 5px;
  }
}

.footer__social-icon:hover {
  background: var(--color-hover);
}

.footer__link {
  color: white;
  border-bottom: 1px solid;
  white-space: nowrap;
}
