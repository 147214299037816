.header-navigation {
  position: absolute;
  z-index: 1;
  width: 100%;
}

.header-navigation__inner {
  padding: var(--padding);
  display: flex;
  justify-content: space-between;
  max-width: 1700px;
  margin: 0 auto;
}

.header-navigation__logo-wrapper {
  width: 70px;
  height: auto;
}

@media (min-width: 680px) {
  .header-navigation__logo-wrapper {
    width: 85px;
  }
}

@media (min-width: 1000px) {
  .header-navigation__logo-wrapper {
    width: 100px;
  }
}

.header-navigation__logo-link {
  line-height: 0;
}

.header-navigation__logo-image {
  width: 100%;
  height: auto;
}

.header-navigation__nav-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(500%);
}

.header-navigation__show .header-navigation__nav-overlay {
  transform: translateX(0%);
  background-color: rgba(220, 220, 220, 0.3);
  transition: background-color 0.2s;
}

.header-navigation__navigation-list {
  width: 100%;
  margin-top: 24px;
}

@media (min-width: 680px) {
  .header-navigation__navigation-list {
    margin-top: 40px;
  }
}

@media (min-width: 1000px) {
  .header-navigation__navigation-list {
    margin-top: 55px;
  }
}

.header-navigation__navigation {
  width: 100%;
  padding: var(--padding);
  position: absolute;
  transform: translateX(100%);
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;
}

@media (min-width: 680px) {
  .header-navigation__navigation {
    width: 50%;
    padding: var(--padding-top) 20px 20px 20px;
  }
}

.header-navigation__show .header-navigation__navigation {
  transform: translateX(0%);
  transition: transform 0.2s ease-out;
}

.header-navigation__burger-bar {
  width: 100%;
  height: 3px;
  background-color: black;
  transform: rotate(45deg);
}

.header-navigation__burger-bar::after {
  width: 100%;
  height: 3px;
  background-color: black;
  transform: rotate(-90deg);
  content: "";
  position: absolute;
}

@media (min-width: 680px) {
  .header-navigation__burger-bar {
    height: 4px;
  }

  .header-navigation__burger-bar::after {
    height: 4px;
  }
}

.header-navigation__burger {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.header-navigation__navigation-wrapper {
  width: 45px;
  height: 17px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
@media (min-width: 680px) {
  .header-navigation__navigation-wrapper {
    height: 20px;
  }
}
