* {
  box-sizing: border-box;
}

body {
  font-size: 16px;
  color: var(--font-color);
  font-family: var(--font);
  line-height: 1.3em;
  font-size: 16px;
}

a {
  color: black;
  text-decoration: none;
}

p {
  font-size: 1em;
  line-height: 1.4;
}

@media (min-width: 1000px) {
  p,
  li {
    font-size: 1.15em;
    line-height: 1.4;
  }
}

@media (min-width: 1440px) {
  p,
  li {
    font-size: 1.3em;
    line-height: 1.4;
  }
}

input,
button,
textarea {
  outline: none;
  line-height: 1.4;
  resize: none;
}
