.cookie-banner {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  color: white;
  padding: var(--padding-sides);
  justify-content: center;
  align-items: center;
  visibility: hidden;
  flex-direction: column;
}

@media (min-width: 500px) {
  .cookie-banner {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
}

@media (min-width: 1000px) {
  .cookie-banner {
    right: 50%;
    padding: 30px 30px 30px var(--padding-sides);
  }
}

.cookie-banner__button {
  border-color: #a7a7a7;
  border-radius: 5px;
  border: 1px solid white;
  color: white;
  background: none;
  cursor: pointer;
  display: inline-block;
  font-family: var(--title);
  font-size: 14px;
  letter-spacing: 0.05rem;
  padding: 11px 15px 10px;
  text-align: center;
  transition: border-color 150ms ease-in-out;
  width: 80px;
}

.cookie-banner p {
  width: 100%;
  padding-right: 0;
  padding-bottom: 20px;
  font-size: 14px;
}

@media (min-width: 500px) {
  .cookie-banner p {
    width: 75%;
    padding-right: 20px;
    padding-bottom: 0;
  }
}

@media (min-width: 1000px) {
  .cookie-banner p {
    width: 100%;
  }
}

.cookie-banner p a {
  color: white;
  text-decoration: underline;
}
