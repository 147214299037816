.tag {
  border-radius: 5px;
  box-shadow: 0px 0px 0px 2px var(--light-gray) inset;
  cursor: pointer;
  display: inline-block;
  font-family: var(--title);
  font-size: var(--meta-s);
  margin: 0px 10px 10px 0px;
  padding: 7px 10px 6px;
  transition-property: color, box-shadow;
  transition-duration: 0.3s;
}

.tag:hover {
  color: #000;
  box-shadow: 0px 0px 0px 2px var(--black) inset;
  transition-property: color, box-shadow;
  transition-duration: 0.3s;
}

.tag--selected {
  box-shadow: 0px 0px 0px 2px var(--black) inset;
  color: #000;
}

.tag--category {
  background: var(--link-grey);
  height: 34px;
}

.tag--category {
  height: 34px;
}
