.kurs__signup-button {
  border-radius: 5px;
  border: none;
  background: none;
  cursor: pointer;
  color: white;
  display: inline-block;
  font-family: var(--title);
  font-size: var(--meta);
  letter-spacing: 0.05rem;
  padding: 13px 17px 12px;
  text-align: center;
  width: 100%;
  background: #64d656;
  transition: 150ms ease-in-out;
}

@media (min-width: 1000px) {
  .kurs__signup-button {
    max-width: unset;
  }
}

.kurs__signup-button:hover {
  background: #6fea60;
}

.kurs__close-signup-form {
  position: absolute;
  height: 40px;
  width: 40px;
  top: 20px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 800px) {
  .kurs__close-signup-form {
    top: 24px;
    right: 52px;
  }
}

.kurs__close-signup-form::before,
.kurs__close-signup-form::after {
  content: "";
  height: 3px;
  width: 100%;
  background: black;
  position: absolute;
}

.kurs__close-signup-form::before {
  transform: rotate(-45deg);
}

.kurs__close-signup-form::after {
  transform: rotate(45deg);
}

@media (min-width: 800px) {
  .kurs__close-signup-form::before,
  .kurs__close-signup-form::after {
    height: 4px;
  }
}

.kurs__close-signup-form:hover {
  cursor: pointer;
}

.kurs__close-signup-form:hover::after,
.kurs__close-signup-form:hover::before {
  background: var(--color-hover);
}
