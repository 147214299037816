.stage {
  padding: 140px var(--padding-sides) 5px;
  max-width: 1700px;
  margin: 0 auto;
}

@media (min-width: 680px) {
  .stage {
    padding: 170px var(--padding-sides) 5px;
  }
}

@media (min-width: 1000px) {
  .stage {
    padding: 200px var(--padding-sides) 10px;
  }
}

@media (min-width: 1440px) {
  .stage {
    padding: 200px var(--padding-sides) 10px;
  }
}

.stage__title {
  font-size: var(--h1);
  font-family: var(--title);
  line-height: var(--h1-lh);
  hyphens: auto;
  -webkit-hyphens: auto;
}

@media (min-width: 680px) {
  .stage__title {
    padding-right: 5%;
  }
}
