.sib-form {
  padding: var(--padding);
  width: 100%;
  max-width: 1700px;
  margin: 100px auto 0;
  position: relative;
}

#sib-container {
  max-width: unset !important;
  padding: 0;
}

@media (min-width: 800px) {
  #sib-form {
    display: grid;
    column-gap: var(--gap);
    grid-template-columns: repeat(2, minmax(1px, 1fr));
    grid-auto-flow: dense;
  }
}

#sib-form > div:first-child {
  grid-column: 1 / span 1;
  grid-row: 1 / span 5;
}

#sib-form > div:nth-child(1) .sib-form-block {
  font-size: var(--h3-s) !important;
  font-family: var(--title) !important;
  line-height: 1;
  color: black !important;
}

@media (min-width: 800px) {
  #sib-form > div:nth-child(1) .sib-form-block {
    font-size: var(--h3-l) !important;
  }
}

#sib-form > div:nth-child(1) .sib-form-block .sib-text-form-block p {
  font-family: var(--font) !important;
  color: black !important;
  width: 80%;
  font-size: 14px !important;
  line-height: var(--meta-lh) !important;
  padding-top: 20px !important;
}

@media (min-width: 680px) {
  #sib-form > div:nth-child(1) .sib-form-block .sib-text-form-block p {
    font-size: 16px !important;
    line-height: var(--meta-lh) !important;
  }
}

#sib-form > div {
  grid-column: 2 / span 1;
  padding: 0 !important;
}

.sib-form-block {
  padding: 0;
}

.sib-form .entry__field {
  width: 100%;
  height: 40px;
  font-size: 1rem;
  font-family: var(--title);
  color: black;
  border-style: solid;
  border: none;
  border-bottom: 2px solid black;
  padding: 0px 6px;
  background-color: white;
  margin-bottom: 12px;
  border-radius: 0;
}

.sib-form .input:first-child,
.sib-form .input__affix:first-child {
  padding-left: 0;
}

.sib-form .entry__field input::placeholder {
  color: black;
}

.sib-form .entry__field:focus-within {
  border: none;
}

#sib-form > div:nth-child(5) span p {
  font-family: var(--font) !important;
  color: black !important;
  font-size: 14px !important;
  line-height: var(--meta-lh) !important;
}

@media (min-width: 680px) {
  #sib-form > div:nth-child(5) span p {
    font-size: 16px !important;
    line-height: var(--meta-lh) !important;
  }
}

.sib-form-block__button {
  border: 2px solid black !important;
  border-radius: 5px !important;
  background: none !important;
  cursor: pointer !important;
  display: inline-block !important;
  font-family: var(--title) !important;
  font-size: var(--meta) !important;
  letter-spacing: 0.05rem !important;
  padding: 12px 15px 10px !important;
  margin-top: 2em !important;
  text-align: center !important;
  width: 50% !important;
  max-width: 350px !important;
  transition: border-color 150ms ease-in-out, color 150ms ease-in-out !important;
  color: black !important;
}

.sib-form-block__button:hover {
  border: 2px solid var(--color-hover);
  color: var(--color-hover);
}

.sib-disclamer-small-text {
  font-family: var(--font) !important;
  color: var(--dark-grey) !important;
  font-size: 11px !important;
  line-height: var(--meta-lh) !important;
  margin-top: 1.5em !important;
}

.sib-disclamer-small-text a {
  color: inherit !important;
}
