.button {
  border-radius: 5px;
  border: 2px solid black;
  background: none;
  cursor: pointer;
  display: inline-block;
  font-family: var(--title);
  font-size: var(--meta);
  letter-spacing: 0.05rem;
  padding: 14px 30px 12px;
  text-align: center;
  width: fit-content;
  max-width: 350px;
  transition: border-color 150ms ease-in-out, color 150ms ease-in-out;
  color: black;
}

.button:hover {
  border-color: #a7a7a7;
}

.button--secondary {
  line-height: var(--meta-lh);
  border: none;
  border-bottom: 2px solid black;
  border-radius: 0;
  margin-top: 50px;
  transition: border-color 70ms ease-in-out, color 70ms ease-in-out;
  max-width: fit-content;
  padding: 0;
}

.button--secondary:hover {
  border-color: var(--color-hover);
}

.button--white {
  border-color: white;
  color: white;
}

.button--white:hover {
  border-color: black;
  color: black;
}
