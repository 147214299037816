.centered-lead {
  padding: var(--padding);
  width: 100%;
  max-width: 1700px;
  margin: 0 auto;
  position: relative;
  font-size: var(--h4);
  line-height: var(--h4-lh);
  font-family: var(--font);
  text-align: center;
  margin: 10px auto 0px auto;
}

@media (min-width: 680px) {
  .centered-lead {
    margin: 60px auto 0px auto;
    max-width: 80%;
  }
}

@media (min-width: 1000px) {
  .centered-lead {
    margin: 60px auto 0px auto;
    max-width: 900px;
  }
}

.centered-lead a {
  text-decoration: underline;
}
